<template>
  <div class="app-wrapper">
    <header class="header--hero">
      <hero-image></hero-image>


      <div class="header-wrappper header-hero__text container">
        <h1 class="heading-1 title">
          ИТ-ПОМОЩНИК
        </h1>
        <span class="subheading">Не нужно ждать приезда специалиста!<br>Все можно сделать онлайн</span>
        <a href="#features" class="teaser-more btn btn--black" data-event="button_more" data-section="teaser"><span>Подробнее</span></a>
        <a href="#subscribe" class="btn btn--inline">
          <svg viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.38827 1.13235C0.589166 0.931454 0.915148 0.932315 1.11498 1.13427L10.5923 10.7122C10.6281 10.7484 10.6574 10.7886 10.6802 10.8313C10.8072 11.0304 10.7831 11.2979 10.6083 11.4709L1.11299 20.8689C0.912547 21.0672 0.589482 21.0664 0.390063 20.867C0.189184 20.6661 0.190024 20.3402 0.391935 20.1403L9.53001 11.096L0.386362 1.85523C0.188016 1.65478 0.188869 1.33175 0.38827 1.13235Z" fill="#ffffff"/>
          </svg>
        </a>
      </div>

      <div class="circle blue parallax"></div>
      <div class="box black parallax"></div>
    </header>

    <main class="main--page">
      <section class="section-wrapper features" id="features">
        <header class="container header--section header-section--features">
          <h2 class="heading-2">
            Что мы делаем
          </h2>
        </header>
        <main class="main--section container">
          <ul class="features-list">
            <li class="features-item">
              <figure class="features-figure">
                <!--                            <img src="img/icon-screen.svg" alt="" class="features-img"/>-->
                <figcaption class="features-caption">
                  <p class="paragraph">
                    удаленно настраиваем смартфон и устанавливаем все необходимые приложения после покупки или ремонта;
                  </p>
                </figcaption>
              </figure>
            </li>
            <li class="features-item">
              <figure class="features-figure">
                <!--                            <img src="img/icon-water.svg" alt="" class="features-img"/>-->
                <figcaption class="features-caption">
                  <p class="paragraph">
                    создаем все необходимые аккаунты;
                  </p>
                </figcaption>
              </figure>
            </li>
            <li class="features-item">
              <figure class="features-figure">
                <!--                            <img src="img/icon-water.svg" alt="" class="features-img"/>-->
                <figcaption class="features-caption">
                  <p class="paragraph">
                    удаленно настраиваем Wi-Fi-сеть и брандмауэр;
                  </p>
                </figcaption>
              </figure>
            </li>
            <li class="features-item">
              <figure class="features-figure">
                <!--                            <img src="img/icon-broke.svg" alt="" class="features-img"/>-->
                <figcaption class="features-caption">
                  <p class="paragraph">
                    подключаем почту;
                  </p>
                </figcaption>
              </figure>
            </li>
            <li class="features-item">
              <figure class="features-figure">
                <figcaption class="features-caption">
                  <p class="paragraph">
                    настраиваем «детский доступ» в интернет;
                  </p>
                </figcaption>
              </figure>
            </li>
            <li class="features-item">
              <figure class="features-figure">
                <figcaption class="features-caption">
                  <p class="paragraph">
                    удаленно устанавливаем бесплатные программы для Windows или macOS;
                  </p>
                </figcaption>
              </figure>
            </li>
            <li class="features-item">
              <figure class="features-figure">
                <figcaption class="features-caption">
                  <p class="paragraph">
                    настраиваем антивирус и удаляем возможные вирусы;
                  </p>
                </figcaption>
              </figure>
            </li>
            <li class="features-item">
              <figure class="features-figure">
                <figcaption class="features-caption">
                  <p class="paragraph">
                    устанавливаем необходимые для работы драйверы;
                  </p>
                </figcaption>
              </figure>
            </li>
            <li class="features-item">
              <figure class="features-figure">
                <figcaption class="features-caption">
                  <p class="paragraph">
                    устанавливаем и настраиваем пакет мультимедиа программ;
                  </p>
                </figcaption>
              </figure>
            </li>
          </ul>
        </main>
      </section>

      <section class="section-wrapper procedure">
        <header class="header--section container header--dark">
          <h2 class="heading-2">С чем работаем</h2>
        </header>
        <main class="main--section container">
          <!-- STEPS -->
          <ul class="procedure-list">
            <li class="procedure-item">
              <figure class="procedure-figure">
                <img src="/img/pc_with_circle.svg" alt="" class="procedure-img"/>
                <figcaption class="procedure-caption">
                  <h3 class="heading-3">1 компьютер</h3>
                </figcaption>
              </figure>
            </li>
            <li class="procedure-item">
              <figure class="procedure-figure or">
                <!--                <svg class="procedure-img plus" xmlns="http://www.w3.org/2000/svg" width="11.7in" height="8.3in" viewBox="0 0 100 50">-->
                <!--                  <g style="stroke-width:.025in; stroke:white; fill:none">-->
                <!--                    <text x="-7" y="75" fill="#ffffff"  font-family="Times"-->
                <!--                          font-style="normal" font-weight="normal" font-size="200" text-anchor="start" >-->
                <!--                      ~</text>-->
                <!--                  </g>-->
                <!--                </svg>-->
                <h2 class="heading-2">или</h2>
              </figure>
            </li>
            <li class="procedure-item">
              <figure class="procedure-figure">
                <img src="img/phone_with_circle.svg" alt="" class="procedure-img"/>
                <figcaption class="procedure-caption">
                  <h3 class="heading-3">1 смартфон</h3>
                </figcaption>
              </figure>
            </li>
          </ul>
        </main>
        <footer class="procedure-footer">
          <h3 class="heading-3">Количество обращений не ограничено!</h3>
        </footer>
      </section>

      <section class="section-wrapper cta" id="subscribe">
        <PhoneInput/>
        <div class="box blue parallax"></div>
      </section>

      <section class="section-wrapper faq">
        <header class="header--section container">
          <h2 class="heading-2">Ответы <br/>на вопросы</h2>
        </header>
        <main class="main--section container">
          <!-- FAQ SECTION-->
          <Faq></Faq>
        </main>
        <img src="/img/figure-wave-pink.svg" alt="" class="parallax wave"/>
        <div class="box blue parallax"></div>
      </section>
    </main>

    <footer class="footer">
      <div class="container">
        <p class="paragraph">
          Услуга предоставляется ООО «Симпл Протект» на условиях
          <a href="/offer_t2_it.pdf" class="link--offer" target="_blank">оферты</a
          >. Оплата осуществляется переводом с лицевого счета абонента Tele2 на
          условиях
          <a
              href="https://f.tele2.ru/offer/"
              class="link--offer"
              target="_blank"
          >оферты</a
          >
          и условиях
          <a href="https://единаякасса.рф/wp-content/uploads/Oferta_platezhnogo_servisa_Tele2_RNKO.pdf"
             class="link--offer" target="_blank"
          >оферты</a
          >
          «ООО РНКО «Единая Касса»». Обращения принимаются по тел.
          <a href="tel:+78005009084" class="section-link">8 (800) 500-90-84</a
          >(круглосуточно).
        </p>
      </div>
      <div class="circle blue parallax"></div>
      <img src="/img/figure-wave-pink.svg" alt="" class="parallax wave"/>
    </footer>
  </div>
</template>

<script>

import HeroImage from "@/components/HeroImage";
import Faq from "./components/Faq";
import PhoneInput from "./PhoneInput";

export default {
  name: 'App',
  components: {
    PhoneInput,
    HeroImage,
    Faq,
  },
  data() {
    return {
      phone: '',
      errors: '',
      STATUS: 'ERROR',
    }
  },
}
</script>
