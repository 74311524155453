<template>
  <div class="container">
    <header class="header--section header-cta" v-show="!showSuccess">
      <h2 class="heading-2">
        Подключите ИТ-помощника<br/>всего
        за <span class="font-title">30</span> рублей в день!
      </h2>
    </header>
    <main class="main--section">
      <form class="form" v-on:submit.prevent data-subscribe-id="4">
        <div class="input-group" :class="this.errorClass" v-if="!this.showSuccess">
          <input type="tel" :value="phone" v-mask="'+7 (###) ### ## ##'" class="form-input " id="phone"
                 v-if="!this.showSmsFields"
                 placeholder="Введите номер телефона" :disabled="STATUS === 'PENDING'" @input="validatePhone"/>
          <label for="phone" data-msg="Заполните правильно это поле" v-if="!this.showSmsFields">Введите номер
            телефона</label>
          <input v-if="this.showSmsFields" id="SMS_CODE_FIELD" :value="smsCode" type="text" placeholder="SMS код"
                 class="form-input sms" @input="validateSms" v-mask="'## ##'" autocomplete="off">
          <label v-if="this.showSmsFields" for="phone" data-msg="Заполните правильно это поле">Введите SMS</label>
          <div class="sign-container">
            <!-- ERROR SIGN-->
            <svg class="sign error" width="4" height="13" viewBox="0 0 4 13" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M2.91933 7.99258C2.91933 8.5003 2.50749 8.91214 1.99977 8.91214C1.49204 8.91214 1.0802 8.5003 1.0802 7.99258V0.919567C1.0802 0.411839 1.49204 0 1.99977 0C2.50749 0 2.91933 0.411839 2.91933 0.919567V7.99258ZM0.932617 11.3314C0.932617 10.7416 1.41068 10.2643 1.99981 10.2643C2.58894 10.2649 3.06701 10.7416 3.06701 11.3314C3.06701 11.9213 2.58894 12.3986 1.99981 12.3986C1.40999 12.3986 0.932617 11.9213 0.932617 11.3314Z"
                    fill="#F53D5C"/>
            </svg>
            <!-- SUCCESS SIGN-->
            <svg class="sign success" width="12" height="9" viewBox="0 0 12 9" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M1 3L5 7L11 1" stroke="#018933" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
          </div>
        </div>
        <div class="form-message">
          <p class="message" v-if="displayMessage">
            {{ this.message }}
          </p>
          <h1 v-if="showSuccess" class="heading-1">Спасибо!<br/>Услуга подключена</h1>
        </div>
        <div v-show="!showSuccess" class="btn-group">
          <button type="submit"
                  v-if="!this.showSmsFields"
                  class="btn btn--black" value="Подключить" @click.prevent="createSubscription">Подключить
          </button>
          <button v-else id="SUBSCRIBE_CONFIRM_BUT" class="btn btn--black" @click.prevent="confirmSubscription">
            Подтвердить
          </button>
        </div>

        <div class="disclaimer-group" v-show="!showSuccess">
          <p class="paragraph">Первый раз оплату спишем завтра</p>
          <p class="paragraph disclaimer">
            Для подключения услуги введите код из SMS с номера 3912, который мы направим на ваш номер телефона.
            Вводя код из SMS и нажимая «Подтвердить», вы принимаете оферты
            <a href="https://f.tele2.ru/offer/" class="link--offer" target="_blank">Tele2</a>,
            <a href="https://единаякасса.рф/wp-content/uploads/Oferta_platezhnogo_servisa_Tele2_RNKO.pdf"
               class="link--offer" target="_blank">«ООО РНКО «Единая Касса»</a> и
            <a href="/offer_t2_it.pdf" class="link--offer" target="_blank">ООО «Симпл Протект»</a>.
          </p>
        </div>


      </form>
    </main>
  </div>
</template>
<script>
import {default as axios} from "axios";
import {mask} from 'vue-the-mask';


/* STATUS
* PENDING
* OK
* ERROR
* */

export default {
  name: 'PhoneInput',
  directives: {mask},
  data() {
    return {
      phone: '',
      errors: '',
      STATUS: '',
      phoneValid: '',
      smsValid: '',
      showSmsFields: '',
      subscribeId: '',
      subscribeType: '4',
      smsCode: '',
      displayMessage: false,
      message: '',
      showSuccess: false,
    }
  },
  computed: {
    errorClass() {
      if (this.STATUS !== 'ERROR' && ((this.phone.length > 1 && this.phoneValid && !this.showSmsFields) || this.smsValid)) {
        return '__success';
      } else if (this.phone.length > 1 && !this.phoneValid || (this.smsCode > 1 && !this.smsValid)) {
        return '__error';
      } else if (this.STATUS === 'ERROR') {
        return '__error';
      } else {
        return '';
      }
    }
  },
  methods: {
    clearErrors() {
      this.STATUS = '';
      this.message = '';
    },
    showMessage(text) {
      this.displayMessage = true;
      this.message = text;
      setTimeout(() => {
        this.displayMessage = false;
        this.clearErrors();
      }, 3000);
    },
    // Show sms input field
    showSmsCode(code) {
      if (!code) return;
      this.showSmsFields = true;
      this.subscribeId = code;
    },
    confirmSubscription() {
      this.STATUS = 'PENDING';
      console.log('Confirm by SMS')
      console.log('SMS code' + this.smsCode)
      console.log('Subs Id' + this.subscribeId)
      console.log('Subs type' + this.subscribeType)

      if (!this.smsCode) {
        this.STATUS = 'ERROR';
        this.showMessage("Напишите код из смс");
        return;
      }
      let url_subscribe = "/server/subscription_confirm.php";

      if (!this.subscribeId) {
        this.STATUS = 'ERROR';
        this.showMessage('Техническая неполадка, нет id подписки');
        return null;
      }

      if (!this.subscribeType) {
        this.STATUS = 'ERROR';
        this.showMessage('Техническая неполадка, нет типа подписки');
        return null;
      }

      //	CONFIRM SUBSCRIPTION
      axios.defaults.headers.post['Content-Type'] = 'application/form-data';
      const data = {
        "subscribe_id": this.subscribeId,
        "subscribe_type": this.subscribeType,
        "sms_code": this.smsCode
      };

      // Convert to FormData
      const smsForm = this.toFormData(data);

      axios.post(url_subscribe, smsForm)
          .then(response => {
            if (response.status === 200 && response.data.error) {
              this.STATUS = 'ERROR';
              this.showMessage(response.data.error);
              // this.errors = response.data.error;
              console.log(response.data.error);
            } else {
              // success message
              this.STATUS = 'SUCCESS';
              // Hide all elements
              this.showSuccess = true;
              // when completed we can move away
            }
          })
          .catch((error) => {
            this.STATUS = 'ERROR';
            this.showMessage(error);
            console.log('error: ' + error);
          });

    },
    createSubscription() {
      console.log('Create subs' + this.subscribeType);
      this.STATUS = 'PENDING';
      this.showMessage('Отправка');
      let url_subscribe = "/server/subscription_create.php";

      if (!this.phone) {
        this.STATUS = 'ERROR';
        this.showMessage("Введите номер телефона");
        return;
      }

      //	CREATE SUBSCRIPTION
      const phone = parseInt(this.phone.match(/[0-9]/g).join(""));

      const data = {
        phone,
        "subscribe_type": this.subscribeType,
      }

      // Convert to FormData
      const phoneForm = this.toFormData(data);

      axios.defaults.headers.post['Content-Type'] = 'application/form-data';

      axios.post(url_subscribe, phoneForm)
          .then(response => {
            if (response.status === 200 && response.data.error) {
              console.log(response.data.error);
              // error message
              this.STATUS = 'ERROR';
              this.showMessage(response.data.error)
            } else {
              // success message
              this.STATUS = '';
              this.subscribeId = response.data.id;
              this.showSmsFields = true;
            }
          })
          .catch((error) => {
            this.STATUS = 'ERROR';
            console.log('error: ' + error);
            this.showMessage(error);
          });
    },
    // Validation SMS & Phone
    validateSms(e) {
      const sms = e.target.value.match(/[0-9]/g)

      if (sms) {
        this.smsCode = String(sms.join(""));

        if (sms.length > 1 && sms.length < 4) {
          this.smsValid = false;
        } else if (sms.length === 4) {
          this.smsValid = true;
        }
      } else {
        this.smsCode = '';
      }
    },
    validatePhone(e) {
      const phoneNumber = e.target.value.match(/[0-9]/g)

      if (phoneNumber) {
        this.phone = String(phoneNumber.join(""));

        if (phoneNumber.length > 1 && phoneNumber.length < 11) {
          this.changeFirstNumber(e);
          this.phoneValid = false;
        } else if (phoneNumber.length === 11) {
          this.phoneValid = true;
        }
      }
    },
    // Change to 7 if first digit is 8, second digit couldn't be 8
    changeFirstNumber(e) {
      const phoneNumber = e.target.value.match(/[0-9]/g)

      if (phoneNumber) {
        this.phone = String(phoneNumber.join(""));

        if (this.phone.length > 1 && this.phone[1] === '8') {
          this.phone = '7';
        }
      }
    },
    // Convert object to FormData
    toFormData(obj) {
      let formData = new FormData();
      for(let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    }
  },
}
</script>