<template>
    <div class="wrapper" @click="toggle" :class="{'active': this.show}">
        <div class="accordion">
            <h3 class="heading-4 faq-title">
                <slot name="title"></slot>
            </h3>
        </div>
        <div class="paragraph faq-answer" v-show="show">
            <slot name="content"></slot>
        </div>
    </div>
</template>


<script>
export default {

    data: function() {
        return {
          show: false,
        };
    },
    methods: {
      toggle() {
        this.show = !this.show;
      }
    }
};
</script>


