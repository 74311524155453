<template>
  <ul class="faq-list">
    <li class="faq-item" v-for="(accordion) in accordions" :key="accordion.id">
      <BaseAccordion>
        <template v-slot:title>{{ accordion.title }}</template>

        <template v-slot:content >
          <div v-html="accordion.text"></div>
        </template>
      </BaseAccordion>
    </li>
  </ul>
</template>

<script>

import BaseAccordion from "./BaseAccordion";

export default {
  components: {
    BaseAccordion
  },
  data() {
    return {
      accordions: [
        {
          id: 1,
          title: 'Как мне помогут? ',
          text: 'Помощь оказывается удаленно, с помощью программ, позволяющих нашему специалисту подключиться к вашему устройству для его настройки и решения проблемы.  Если у вас нет таких программ, наши специалисты расскажут по телефону как их установить и помогут вам в этом процессе.'
        },
        {
          id: 2,
          title: 'Как связаться с поддержкой?',
          text: 'Вы можете направить заявку с описанием вашей проблемы на <a href="mailto:info@simpleprotect.ru">info@simpleprotect.ru</a> или по телефону <a href="tel:88005009084">8&nbsp;800&nbsp;500&nbsp;90&nbsp;84</a>. При обращении будет назначено время, в которое специалист свяжется с вами.',
        },
        {
          id: 3,
          title: 'Как отключить подписку?',
          text: 'Отправить бесплатное SMS со словом СТОП на 3912.'
        },
        {
          id: 4,
          title: 'Что делать если устройство заблокировалось, слетели настройки, не работают программы, что-то сломалось и я не могу в него войти или воспользоваться?',
          text: 'Мы приложим все усилия, чтобы решить проблему дистанционно. Если это невозможно, необходимо обратиться с устройством в один из 1300 наших сервисных центров, где специалисты разберутся в проблеме на месте. По телефону мы подскажем адрес ближайшего к вам сервисного центра.'
        },
        {
          id: 5,
          title: 'Какие программы будут использоватся?',
          text: 'Для оказания услуги используются программы TeamViewer, AirMirror и аналогичные программы удаленного доступа к устройству.'
        },
        {
          id: 6,
          title: 'Какие данные об устройствах нужно вводить при подключении услуги?',
          text: 'Данные вводить не нужно, сведения об устройстве мы зафиксируем в момент первого обращения.'
        },
      ]
    };
  },
  methods: {
  }
};
</script>
